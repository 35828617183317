<template>
    <div
        class="ui-list-group-item"
        :class="listGroupItemClasses"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'ListGroupItem',
    props: {
        active: {
            type: Boolean,
            default: false,
            required: false,
        },
        action: {
            type: Boolean,
            default: false,
            required: false,
        },
        paddingVariant: {
            type: String,
            default: 'md',
            required: false,
        },
        variant: {
            type: String,
            default: null,
            required: false,
        },
    },
    computed: {
        listGroupItemClasses() {
            const classes = [`ui-list-group-item--padding-${this.paddingVariant}`];

            if (this.active) {
                classes.push('ui-list-group-item--active');
            }

            if (this.action) {
                classes.push('ui-list-group-item--action');
            }

            if (this.variant) {
                classes.push(`ui-list-group-item--${this.variant}`);
            }

            return classes;
        },
    },
};
</script>

<style lang="scss">
.ui-list-group-item {
    border: 1px solid transparent;
    display: block;
    transition: 300ms ease-out;

    &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    &:not(:last-child) {
        border-bottom: 1px solid #dae0ec;
    }

    &--active {
        background-color: $ecart-primary-100;
        border-color: $ecart-primary;
        border-radius: 0.7rem;
    }

    &--action {
        cursor: pointer;

        &:hover {
            background-color: $ecart-primary-100;
        }
    }

    &--padding-sm {
        padding: 16px;
    }

    &--padding-md {
        padding: 24px;
    }
}
</style>
