<template>
    <div class="ui-list-group" :class="listGroupClasses">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'ListGroup',
    props: {
        bordered: {
            type: Boolean,
            default: false,
            required: false,
        },
        shadow: {
            type: Boolean,
            default: false,
            required: false,
        },
        variant: {
            type: String,
            default: null,
            required: false,
        },
    },
    computed: {
        listGroupClasses() {
            const classes = [];

            if (this.variant) {
                classes.push(`ui-list-group--${this.variant}`);
            }

            if (this.bordered) {
                classes.push('ui-list-group--bordered');
            }

            if (this.shadow) {
                classes.push('ui-list-group--shadow');
            }

            return classes;
        },
    },
};
</script>

<style lang="scss">
.ui-list-group {
    padding: 0;
    background-color: $general-white;
    display: flex;
    flex-direction: column;
    border-radius: 8px;

    @media (max-width: 768px) {
        padding: 16px 8px;
    }

    &--white {
        background-color: $white;
    }

    &--bordered {
        border: 1px solid #dae0ec;
    }

    &--shadow {
        box-shadow: 0px 8px 16px rgba(#000000, 0.15);
    }
}
</style>
