<template>
    <div class="list-group-skeleton">
        <list-group>
            <list-group-item v-for="index in rows" :key="index">
                <div class="list-group-skeleton__item">
                    <div class="list-group-skeleton__details">
                        <ui-skeleton class="list-group-skeleton__image" />
                        <div class="w-100">
                            <ui-skeleton class="list-group-skeleton__title" />
                            <ui-skeleton class="list-group-skeleton__text" />
                        </div>
                    </div>
                    <div class="list-group-skeleton__actions">
                        <ui-skeleton class="list-group-skeleton__action" />
                    </div>
                </div>
            </list-group-item>
        </list-group>
    </div>
</template>

<script>
import ListGroup from '@/components/ui/list-group/ListGroup.vue';
import ListGroupItem from '@/components/ui/list-group/ListGroupItem.vue';
import UiSkeleton from '@/components/ui/skeleton/Skeleton.vue';

export default {
    name: 'ListGroupSkeleton',
    components: {
        ListGroup,
        ListGroupItem,
        UiSkeleton,
    },
    props: {
        rows: {
            type: Number,
            default: 10,
            required: false,
        },
    },
    data() {
        return {};
    },
};
</script>

<style lang="scss">
.list-group-skeleton {
    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
    }

    &__details {
        align-items: center;
        display: flex;
        flex-shrink: 1;
        width: 100%;
        gap: 8px;
    }

    &__image {
        height: 50px;
        width: 50px;
        margin-bottom: 0;
    }

    &__title {
        max-width: 300px;
        width: 100%;
    }

    &__text {
        margin-bottom: 0;
        max-width: 500px;
        width: 100%;
    }

    &__action {
        width: 90px;
        margin-bottom: 0;

        @media (max-width: 768px) {
            width: 100%;
        }
    }
}
</style>
